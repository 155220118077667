#hero {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background: rgb(214, 214, 214);
}

@media screen and (max-width: 480px) {
  #hero {
    width: 100vw;
    height: auto;
  }

  #hero .display-2 {
    font-size: 3.75rem;
  }
}

#hero .the-inner {
  background-color: rgba(0, 0, 0, 0.8);
  width: 90vw;
  padding: 1rem;
}

#hero .the-inner h1,
h4 {
  color: white;
}

#hero h1 {
  padding: 0;
  margin: 0;
}

#hero .the-inner p {
  color: rgb(250, 249, 249);
}

.the-inner a {
  color: #000000;
}

.the-inner .points p:before {
  border: 1px red solid;
  content: url(https://chrislanejones.com/wp-content/uploads/2018/10/littlesquarebullet.png);
  padding: 0 1rem 0 0;
}

/* special thanks to https://codetheweb.blog/demo/full-image-hero/ */
