@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville);
body {
  margin: 0;
  padding: 0;

  font-family: "Libre Baskerville", serif;
}

h1 {
  color: #000;
  font-style: italic;
  font-weight: 400;
  line-height: 1.4;
  font-size: 2.1rem;
}

p {
  color: #000;
  text-align: justify;
  font-size: 1.1rem;
}

#hero {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background: rgb(214, 214, 214);
}

@media screen and (max-width: 480px) {
  #hero {
    width: 100vw;
    height: auto;
  }

  #hero .display-2 {
    font-size: 3.75rem;
  }
}

#hero .the-inner {
  background-color: rgba(0, 0, 0, 0.8);
  width: 90vw;
  padding: 1rem;
}

#hero .the-inner h1,
h4 {
  color: white;
}

#hero h1 {
  padding: 0;
  margin: 0;
}

#hero .the-inner p {
  color: rgb(250, 249, 249);
}

.the-inner a {
  color: #000000;
}

.the-inner .points p:before {
  border: 1px red solid;
  content: url(https://chrislanejones.com/wp-content/uploads/2018/10/littlesquarebullet.png);
  padding: 0 1rem 0 0;
}

/* special thanks to https://codetheweb.blog/demo/full-image-hero/ */

#portfolio {
  padding-top: 60px;
}

#portfolio .flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}

/* #portfolio h1 {
  padding: 10px;
}

#portfolio p {
  padding-bottom: 5px;
} */

#portfolio figure {
  position: relative;
  margin: 0;
  padding: 0;
}

/* #portfolio figure:hover .overlay {
  opacity: 0.6;
} */

#portfolio .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.5s ease;
  background: #ece9e6; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffffff,
    #ece9e6
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  opacity: 0;
}

#portfolio .overlay p {
  position: absolute;
  bottom: 0;
  color: black;
  margin: 0;
}

.alert {
  position: fixed !important;
  top: 10px;
  left: 60%;
  width: 600px;
}

#contact {
  margin-top: 60px;
  margin-bottom: 80px;
}

/* #contact h1 {
  padding: 20px;
} */

footer {
  padding: 30px;
}

footer .col {
  padding: 10px;
}

footer h1 {
  color: white;
}

footer p {
  color: rgb(196, 194, 194);
}

