footer {
  padding: 30px;
}

footer .col {
  padding: 10px;
}

footer h1 {
  color: white;
}

footer p {
  color: rgb(196, 194, 194);
}
