#portfolio {
  padding-top: 60px;
}

#portfolio .flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}

/* #portfolio h1 {
  padding: 10px;
}

#portfolio p {
  padding-bottom: 5px;
} */

#portfolio figure {
  position: relative;
  margin: 0;
  padding: 0;
}

/* #portfolio figure:hover .overlay {
  opacity: 0.6;
} */

#portfolio .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.5s ease;
  background: #ece9e6; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffffff,
    #ece9e6
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffffff,
    #ece9e6
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  opacity: 0;
}

#portfolio .overlay p {
  position: absolute;
  bottom: 0;
  color: black;
  margin: 0;
}
