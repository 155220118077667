@import url("https://fonts.googleapis.com/css?family=Libre+Baskerville");

body {
  margin: 0;
  padding: 0;

  font-family: "Libre Baskerville", serif;
}

h1 {
  color: #000;
  font-style: italic;
  font-weight: 400;
  line-height: 1.4;
  font-size: 2.1rem;
}

p {
  color: #000;
  text-align: justify;
  font-size: 1.1rem;
}
